import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["shipping_address", "delivery_vendor", "ticket_id", "delivery_vendor_note", "area_id", "warehouse_code"];

    connect() {

        this.portea_id = $(this.delivery_vendorTarget).data('portea-id');

        console.log("sales order loaded");

        this.populate_shipper($(this.shipping_addressTarget).val(), true);

        $(this.shipping_addressTarget).on('change', (e) => {
            this.populate_shipper($(this.shipping_addressTarget).val(), false);
        });

        this.display_vendor_note($(this.delivery_vendorTarget).val());

        $(this.delivery_vendorTarget).on('change', (e) => {
            this.display_vendor_note($(this.delivery_vendorTarget).val());
        });

        $(this.area_idTarget).on("change", (e) => {
            this.populate_warehouse_codes($(this.area_idTarget).val());
        });

    }

    populate_shipper(address_id, called_on_load) {

        const ticket_id = $(this.ticket_idTarget).val();

        const delivery_vendor = $(this.delivery_vendorTarget);
        const selected_vendor = delivery_vendor.val();

        delivery_vendor.empty();

        if(address_id != '') {
            fetch('/matching_vendors/' + address_id + '/' + ticket_id, {
                method: 'GET',
                credentials: "same-origin"
            }).then((response) => response.json())
            .then((json) => {
                const default_selected = called_on_load && (this.portea_id == selected_vendor)  ? true : false;
                json.forEach((option) => {
                    const default_selected = called_on_load && (option.id == selected_vendor)  ? true : false;
                    const o = new Option(option.name, option.id, default_selected, default_selected);
                    delivery_vendor.append($(o))
                });
                if(this.portea_id != null){
                    const o = new Option('Door Delivery', this.portea_id, default_selected, default_selected);
                    delivery_vendor.append($(o))
                }
                delivery_vendor.material_select();
            }).catch((err) => {
                console.log(err);
                alert( err + ' Please contact support.');
            } ) ;
        } else {
            delivery_vendor.empty();
            delivery_vendor.material_select();
        }

    }

    display_vendor_note(vendor_id){
        if(vendor_id != null && vendor_id == this.portea_id){
            $(this.delivery_vendor_noteTarget).show();
        }else{
            $(this.delivery_vendor_noteTarget).hide();
        }
    }

    // Warehouse Code Methods
    populate_warehouse_codes(selected_area) {
        const warehouse_code = $(this.warehouse_codeTarget);

        warehouse_code.empty();
        warehouse_code.append(new Option('', ''));
        $(warehouse_code).material_select();

        if (selected_area) {
            fetch('/areas/' + selected_area + '/warehouses', {
                method: "GET",
                headers: {"Content-Type": "application/json"},
            })
                .then((response) => response.json())
                .then((json) => {
                    json.warehouse_codes.forEach((code) => {
                        const option = new Option(code, code);
                        warehouse_code.append(option);});
                    $(warehouse_code).material_select();
                })
                .catch((error) => {
                    console.error("Error fetching warehouse codes:", error);
                });
        }
    }
}
