import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.element.addEventListener("click", this.hideElement.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("click", this.hideElement.bind(this));
    }

    hideElement(event){
        event.preventDefault();
        event.target.style.display = "none";
    }
}
